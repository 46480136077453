import { userPrivacyPolicy } from '@/service/footer';
import { getCookieUser } from 'lowcode/BhStat/utils/cookieFn';
import { useEffect, useState } from 'react';
import css from './index.module.scss';

export default function CCPAModal({ callback, isLogin }: any) {
    const [content, setContent] = useState('');
    const [btnText, setBtnText] = useState('');
    const [submitError, setSubmitError] = useState();
    const i18n = {
        login: {
            text: 'In accordance with the CCPA, California residents have the right to opt out of the sale or sharing of their personal information. To exercise this right, please click the "Confirm" button below to finalize your choice.',
            btn: 'Confirm',
        },
        unlogin: {
            text: 'Please log in to proceed;\npreferences will not be saved if not logged in.',
            btn: 'Log In',
        },
    };

    useEffect(() => {
        const text = isLogin ? i18n.login.text : i18n.unlogin.text;
        const btn = isLogin ? i18n.login.btn : i18n.unlogin.btn;
        setContent(text);
        setBtnText(btn);
    }, [isLogin]);

    const clickBtn = async (e) => {
        const userInfo = getCookieUser();
        const isProd = window?.location?.href?.startsWith('https://www.ecoflow.com');
        if (!isLogin) {
            window.location.href = `${isProd ? 'https://www.ecoflow.com/us/login' : 'https://release-test.ecoflow.com/us/login'}?redirect=${
                window.location.href
            }`;
        } else {
            const API = isProd ? 'https://api-a.ecoflow.com' : 'https://api-sit.ecoflow.com';
            const res = await userPrivacyPolicy(`${API}/website/userPrivacyPolicy`, { accept: true, userId: userInfo?.user?.userId });

            if (res?.code === '0') {
                callback();
            } else {
                setSubmitError(res?.message);
                setTimeout(() => {
                    callback();
                }, 3000);
            }
        }
    };
    return (
        <div className={`ccpa-modal ${css['ccpa-modal']}`}>
            <div className="modal-box">
                <span className="header-close" onClick={callback}>
                    &times;
                </span>
                <div className="modal-content">{content}</div>
                <div className="modal-btn" onClick={clickBtn}>
                    {btnText}
                </div>
                <div className="modal-submit-error">{submitError}</div>
            </div>
            <style global jsx>
                {`
                    .ecoflow-frame-header {
                        z-index: 100 !important;
                    }
                `}
            </style>
        </div>
    );
}
