'use client';

import EcoDesignEventsBus from 'eco-design-micro/EcoDesignEventsBus';
import EcoStat from 'eco-design-reliance/EcoStat';
import { useEffect } from 'react';

const moduleIdObjA: any = {}; //用于记录只能访问1次
const dataTimeObjA: any = {}; //用于记录进入时间
const minTimerValidNumber = 2; //至少停留多少秒才有效
export default function GtmModuleData() {
    // 进行处理
    const itemHandle = (elemItem: any, { moduleId, status }: { moduleId?: string; status?: 'enter' | 'leave' }) => {
        if (!elemItem) {
            return;
        }
        if (!elemItem.getAttribute) {
            return;
        }

        if (!moduleId) {
            return;
        }
        if (dataTimeObjA[`${moduleId}-status`] === status) {
            // 防止多次触发(已进入或离开时都会触发多次)
            return;
        }
        if (status === 'enter') {
            dataTimeObjA[`${moduleId}-status`] = 'enter';
        } else if (status === 'leave') {
            dataTimeObjA[`${moduleId}-status`] = 'leave';
        }
        const enterCount = dataTimeObjA[`${moduleId}-count`]; //第几次进入
        if (!enterCount) {
            return;
        }
        // 获取标题
        const title1 = elemItem.getAttribute('data-section-title'); //从 data-title 中直接拿出标题
        let title2 = '';
        if (!title1) {
            const dataClassName = elemItem?.getAttribute('data-section-classname'); //从子项的classname中取出标题
            title2 = dataClassName ? elemItem?.querySelector(dataClassName)?.innerText : '';
        }
        const title = title1 || title2;

        const enterTime = dataTimeObjA[`${moduleId}-enter`]; //进入时间
        const leaveTime = dataTimeObjA[`${moduleId}-leave`]; //离开时间
        if (!enterTime) {
            return;
        }

        // 进入模块;离开模块时才发送数据
        if (status === 'enter') {
            // EcoStat.pageModuleCreate({
            //     moduleId,
            //     enterCount,
            //     title,
            //     enterTime,
            // });
        }
        if (!leaveTime) {
            return;
        }
        // 离开时间小于进入时间,说明它是2次进入
        if (leaveTime < enterTime) {
            return;
        }
        const duration1 = leaveTime - enterTime;
        const duration2 = (duration1 / 1000).toFixed(2);
        const duration = Number(duration2); //停留了多少秒

        // 要发送的数据;只发送大于1s的数据;避免划动过快时产生太多数据
        if (status === 'leave' && duration >= minTimerValidNumber && duration < 10000000) {
            EcoStat.pageModuleCreate({
                moduleId,
                enterCount,
                enterTime,
                leaveTime,
                duration, //停留时间
                title,
            });
            dataTimeObjA[`${moduleId}-enter`] = null;
            dataTimeObjA[`${moduleId}-leave`] = null;
        }
    };
    // 监听单个
    const initGtmItemHandle = (elem: any, moduleId: string) => {
        const observer = new IntersectionObserver(
            (elemA: any) => {
                const elemB = elemA[0];

                if (elemB.isIntersecting) {
                    // 进入可视区
                    dataTimeObjA[`${moduleId}-count`] = (dataTimeObjA[`${moduleId}-count`] || 0) + 1; //第几次进入
                    dataTimeObjA[`${moduleId}-enter`] = Date.now(); // 记录进入可视区的时间
                    itemHandle(elem, { moduleId, status: 'enter' });
                } else {
                    // 离开可视区
                    dataTimeObjA[`${moduleId}-leave`] = Date.now(); // 记录离开可视区的时间
                    itemHandle(elem, { moduleId, status: 'leave' });
                }
            },
            { threshold: 0.1 },
        ); // 触发的阈值（0.1 表示 10% 可见时触发）
        observer.observe(elem);
    };
    // 开始
    const initGtmModuleHandle = () => {
        const elemList = document.querySelectorAll('.GtmModuleData-section'); //要监听的元素列表
        for (let i = 0; i < elemList.length; i++) {
            const elemItem = elemList[i];
            const moduleIdA = elemItem.getAttribute('data-section-id') || ''; //主ID
            const moduleIdB = elemItem.getAttribute('data-section-id-2') || ''; //辅助ID(同一个组件,一个页面可能用了多次)
            const moduleId = `${moduleIdA}${moduleIdB}`;
            // 必须要有-模块ID
            if (!moduleId) {
                continue;
            }
            // 模块ID-只能监听1次
            if (moduleIdObjA[moduleId]) {
                continue;
            }
            initGtmItemHandle(elemItem, moduleId);
            moduleIdObjA[moduleId] = true;
        }
    };
    useEffect(() => {
        setTimeout(() => {
            initGtmModuleHandle();
        }, 1500);
        setTimeout(() => {
            initGtmModuleHandle();
        }, 3500);
        // 执行监听;用于切到tab时没有监听到的元素
        EcoDesignEventsBus.on('GtmModuleData-start', () => {
            initGtmModuleHandle();
        });
    }, []);
    return null;
}
