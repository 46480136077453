import { useRouter } from 'next/router';
import { useEffect } from 'react';
import BhStat from '.';

export default function BhStatCommonGa4() {
    const { locale = 'us' } = useRouter();
    useEffect(() => {
        setTimeout(() => {
            BhStat.init({
                locale,
            });
            window.addEventListener('beforeunload', () => {
                BhStat.leavePage();
            });
            document.addEventListener('visibilitychange', () => {
                if (document.visibilityState === 'hidden') {
                    BhStat.leavePage();
                }
            });
        }, 1000);
    }, []);

    return null;
}
