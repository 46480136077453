import GtmModuleData from 'eco-design-micro/GtmModuleData';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import BhStatCommonGa4 from './BhStatCommonGa4';

const isCypressTestFn = () => {
    if (typeof window !== 'undefined') {
        const userAgent = window?.navigator?.userAgent || '';
        const devices = userAgent.toLowerCase() || '';
        if (devices.indexOf('iscypresstest') > 0) {
            return true;
        }
    }
    return false;
};

export default function BhStatCommon() {
    const { locale = 'us' } = useRouter();
    const [openStatFlag, setOpenStatFlag] = useState(false);
    useEffect(() => {
        // 是否是自动化脚本
        const isCypressTest = isCypressTestFn();
        if (!isCypressTest && ['sa'].includes(locale)) {
            setOpenStatFlag(true);
        }
    }, []);
    return (
        <>
            {openStatFlag && (
                <>
                    <GtmModuleData />
                    <BhStatCommonGa4 />
                </>
            )}
        </>
    );
}
